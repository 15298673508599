import capitalize from 'lodash/capitalize';
import { useMemo } from 'react';

import {
  type DtoProduct,
  EnumsProductBillingInterval,
} from '@lp-lib/api-service-client/public';

const billingIntervalOptions = Object.values(EnumsProductBillingInterval).map(
  (t) => ({
    label: capitalize(t.toString()),
    value: t,
  })
);

export function useBillingIntervalOptions(products: DtoProduct[]) {
  return useMemo(() => {
    const presentIntervals = new Set(
      products
        .flatMap((p) => p.prices ?? [])
        .filter((p) => !p.archived)
        .map((p) => p.billingInterval)
    );
    const options = [...billingIntervalOptions];
    // delete the option if it's not present
    for (const option of options) {
      if (!presentIntervals.has(option.value)) {
        options.splice(options.indexOf(option), 1);
      }
    }
    return options;
  }, [products]);
}
