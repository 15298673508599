import { useEffect, useMemo, useRef, useState } from 'react';

import { type Option } from './Utilities';

export type SegmentedControlStyles = {
  glider?: string;
  track?: string;
  option?: string;
  selectedOption?: string;
  unselectedOption?: string;
};

const defaultStyles: SegmentedControlStyles = {
  glider: 'btn-primary',
  track: 'bg-black rounded-2xl p-1 border border-secondary',
  option: 'btn px-6 py-2 transition-colors',
  selectedOption: 'text-white',
  unselectedOption: 'text-icon-gray hover:text-white',
};

export function SegmentedControl<T>(props: {
  options: Option<T>[];
  defaultValue?: Option<T>;
  value?: Option<T>;
  onChange?: (option: Option<T>) => void;
  styles?: SegmentedControlStyles;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const gliderRef = useRef<HTMLDivElement>(null);
  const buttonsRef = useRef<Map<T, HTMLButtonElement | null>>(new Map());
  // we want to avoid animate on initial render in case the default value is not the first option
  const shouldAnimate = useRef(false);
  const styles = useMemo(
    () => ({ ...defaultStyles, ...props.styles }),
    [props.styles]
  );

  const isControlled = props.value !== undefined;
  const [uncontrolledSelected, setUncontrolledSelected] = useState(
    props.defaultValue ?? props.options[0]
  );
  const selected = isControlled ? props.value : uncontrolledSelected;

  const handleSelect = (option: Option<T>) => {
    if (isControlled) {
      props.onChange?.(option);
    } else {
      setUncontrolledSelected(option);
    }
  };

  useEffect(() => {
    if (!selected) return;
    const selectedButton = buttonsRef.current.get(selected.value);
    if (containerRef.current && gliderRef.current && selectedButton) {
      const containerPosition = containerRef.current.getBoundingClientRect();
      const buttonPosition = selectedButton.getBoundingClientRect();
      const offset = buttonPosition.left - containerPosition.left;

      gliderRef.current.style.width = `${buttonPosition.width}px`;
      gliderRef.current.style.height = `${buttonPosition.height}px`;
      gliderRef.current.style.transform = `translateX(${offset}px)`;
      shouldAnimate.current = true;
    }
  }, [selected]);

  return (
    <div
      ref={containerRef}
      className={`relative flex justify-center items-center ${styles.track}`}
    >
      <div
        ref={gliderRef}
        className={`absolute left-0 ${
          shouldAnimate.current ? 'duration-300' : ''
        }`}
      >
        <div className={`w-full h-full ${styles.glider}`} />
      </div>

      {props.options.map((option, index) => (
        <button
          key={index}
          ref={(el) => buttonsRef.current.set(option.value, el)}
          type='button'
          onClick={() => handleSelect(option)}
          className={`relative ${styles.option} ${
            selected?.value === option.value
              ? styles.selectedOption
              : styles.unselectedOption
          }`}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
}
